import { initWeb3W } from 'web3w';
import { WalletConnectModuleLoader } from 'web3w-walletconnect-loader';
import { contractsInfo } from '$lib/contracts';
import _contractsInfo from '$lib/contracts.json';
import { notifications } from '$lib/notifications';
import { webWalletURL, finality, fallbackProviderOrUrl, chainId, localDev } from '$lib/config';
import { isCorrected, correctTime } from '$lib/time';
import { base } from '$app/paths';
import { chainTempo } from '$lib/chainTempo';
import * as Sentry from '@sentry/browser';
import { get, writable } from 'svelte/store';

const walletStores = initWeb3W({
    chainConfigs: get(contractsInfo),
    ensAddress: _contractsInfo[chainId][0].contracts.KNSRegistry.address,
    builtin: { autoProbe: true },
    transactions: {
        autoDelete: false,
        finality,
    },
    flow: {
        autoUnlock: true,
    },
    autoSelectPrevious: true,
    localStoragePrefix: (base && base.startsWith('/ipfs/')) || base.startsWith('/ipns/') ? base.slice(6) : undefined, // ensure local storage is not conflicting across web3w-based apps on ipfs gateways
    options: [
        'builtin',
        new WalletConnectModuleLoader({
            nodeUrl: webWalletURL,
            chainId,
        }),
    ],
    fallbackNode: fallbackProviderOrUrl,
    checkGenesis: localDev,
});

export const { wallet, transactions, builtin, chain, balance, flow, fallback } = walletStores;

function notifyFailure(tx: { hash: string }) {
    notifications.enqueue({
        id: tx.hash,
        delay: 3500,
        title: 'Transaction Error',
        text: 'The transaction failed',
        type: 'error',
        onAcknowledge: () => transactions.acknowledge(tx.hash, 'failure'),
    });
}

function notifyCancelled(tx: { hash: string }) {
    notifications.enqueue({
        id: tx.hash,
        delay: 3500,
        title: 'Transaction Cancelled',
        text: 'The transaction has been replaced',
        type: 'warning',
        onAcknowledge: () => transactions.acknowledge(tx.hash, 'cancelled'),
    });
}

transactions.subscribe(($transactions) => {
    for (const tx of $transactions.concat()) {
        if (tx.confirmations > 0 && !tx.acknowledged) {
            if (tx.status === 'failure') {
                notifyFailure(tx);
            } else if (tx.status === 'cancelled') {
                notifyCancelled(tx);
            } else {
                // auto acknowledge
                transactions.acknowledge(tx.hash, tx.status);
            }
        }
    }
});

chain.subscribe(async (v) => {
    chainTempo.startOrUpdateProvider(wallet.provider);
    if (!isCorrected()) {
        if (v.state === 'Connected' || v.state === 'Ready') {
            const latestBlock = await wallet.provider?.getBlock('latest');
            if (latestBlock) {
                correctTime(latestBlock.timestamp);
            }
        }
    }
});

fallback.subscribe(async (v) => {
    if (!isCorrected()) {
        if (v.state === 'Connected' || v.state === 'Ready') {
            const latestBlock = await wallet.provider?.getBlock('latest');
            if (latestBlock) {
                correctTime(latestBlock.timestamp);
            }
        }
    }
});

let lastAddress: string | undefined;
wallet.subscribe(async ($wallet) => {
    if (lastAddress !== $wallet.address) {
        lastAddress = $wallet.address;
        Sentry.setUser({ address: $wallet.address });
    }
});

// TODO remove
if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).walletStores = walletStores;
}

chainTempo.startOrUpdateProvider(wallet.provider);

contractsInfo.subscribe(async ($contractsInfo) => {
    await chain.updateContracts($contractsInfo);
});
