export type ChainInfo = {
    id: number;
    name: string;
    rpcUrl?: string;
    rpcUrlWs?: string;
    blockExplorerUrl?: string;
    nativeCurrency?: {
        name: string;
        symbol: string;
        decimals: number;
    };
}

export const chainInfo: { [chainId: string]: ChainInfo } = {
    '321': {
        id: 323,
        name: 'KCC Mainnet',
        rpcUrl: 'https://rpc-mainnet.kcc.network',
        nativeCurrency: {
            name: 'KuCoin Shares', symbol: 'KCS', decimals: 18,
        },
    },
    '322': {
        id: 321,
        name: 'KCC Testnet',
        rpcUrl: 'https://rpc-testnet.kcc.network',
        nativeCurrency: {
            name: 'KuCoin Shares',
            symbol: 'KCS',
            decimals: 18,
        },
    },
    '1337': { id: 1337, name: 'localhost' },
    '31337': { id: 31337, name: 'hardhat' },
};

export function nameForChainId(chainId: string): string {
    const name = chainInfo[chainId].name;
    if (name) {
        return name;
    }

    return `chain with id ${chainId}`;
}
