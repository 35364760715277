import _contractsInfo from '$lib/contracts.json';
import { readable } from 'svelte/store';
import { chainId } from '$lib/config';


let _set: { (arg0: any): void; (value: {}): void; };

export const contractsInfo = readable(_contractsInfo[chainId]?.[0], (set) => {
    _set = set;
});

if (import.meta.hot) {
    import.meta.hot.accept((newModule) => {
        _set(newModule.contractsInfo);
    });
}

