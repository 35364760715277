import { get, writable } from 'svelte/store';
import { v4 as uuidv4 } from 'uuid';

export type NotificationType = 'error' | 'success' | 'info' | 'warning';

export type Notification = {
    id?: string;
    delay: number;
    onAcknowledge?: () => void;
    title: string;
    text: string;
    type: NotificationType;
};

const pending = writable<Notification[]>([]);

function acknowledge(id: string) {
    const notification = get(pending).find((notification) => notification.id === id);
    if (!notification) {
        return;
    }

    pending.set(get(pending).filter((notification) => notification.id !== id));

    if (notification.onAcknowledge) {
        notification.onAcknowledge();
    }
}

function enqueue(notification: Notification) {
    if (!notification.id) {
        notification.id = uuidv4();
    }
    pending.set([...get(pending), notification]);
    setTimeout(() => acknowledge(notification.id), notification.delay);
}

function acknowledgeAll() {
    get(pending).map((notification) => acknowledge(notification.id));
}

function clear() {
    pending.set([]);
}

export const notifications = {
    subscribe: pending.subscribe,
    enqueue,
    acknowledge,
    acknowledgeAll,
    clear
};


// USEFUL FOR DEBUGGING:
if (typeof window !== 'undefined') {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    (window as any).notifications = notifications;
    /* eslint-enable @typescript-eslint/no-explicit-any */
}

